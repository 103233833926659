import React, { Component } from 'react'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney'

import Datatable from '../../../ui/Datatable'
import InfoModal from '../../../ui/InfoModal'
import { fetchConsumerCredits, clearError } from '../../../../actions'
import ErrorBoundary from '../../../layout/ErrorBoundary'
import { AccountPageCreditsListItem } from './AccountPageCreditsListItem'
import { AccountPageCreditsEmpty } from './AccountPageCreditsEmpty'

export class CreditsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            loading:
                typeof props.loading !== 'undefined' ? props.loading : true,
        }
        this.handleErrorDismiss = this.handleErrorDismiss.bind(this)
    }

    componentDidMount() {
        return this.props.fetchConsumerCredits(1).finally(() =>
            this.setState({
                loading: false,
            })
        )
    }

    async fetchMoreCredits() {
        if (!this.props.hasMoreCredits) {
            return
        }
        await this.props.fetchConsumerCredits(this.state.currentPage + 1)

        this.setState({
            currentPage: this.state.currentPage + 1,
        })
    }

    handleErrorDismiss() {
        this.props.clearServerError()
    }

    render() {
        const { credits, hasMoreCredits, totalValue, serverError } = this.props

        if (this.state.loading) {
            return (
                <Segment>
                    <Dimmer
                        active
                        inverted
                        style={{ height: '300px' }}
                    >
                        <Loader
                            inverted
                            content="Chargement"
                        />
                    </Dimmer>
                </Segment>
            )
        }

        if (!credits || credits.length === 0) {
            return <AccountPageCreditsEmpty />
        }

        return (
            <div>
                <InfoModal
                    show={!!serverError}
                    onClose={this.handleErrorDismiss}
                    isError={true}
                />

                <h3 style={{ textAlign: 'right' }}>
                    Valeur actuel: {formatMoney.run(totalValue)}
                </h3>

                <Datatable
                    listParams={{
                        header: {
                            columns: [
                                { name: 'createdAt', title: 'Date' },
                                {
                                    name: 'producer_name',
                                    title: 'Produit',
                                    hidden: 600,
                                },
                                { name: 'amount_initial', title: 'Initial' },
                                { name: 'amount_remaining', title: 'Restant' },
                            ],
                        },
                        RowComponent: AccountPageCreditsListItem,
                        fetchMore: this.fetchMoreCredits.bind(this),
                    }}
                    items={credits}
                    hasMore={hasMoreCredits}
                />
            </div>
        )
    }
}

export const AccountPageCredits = ({
    credits,
    totalValue,
    hasMoreCredits,
    fetchConsumerCredits,
    clearServerError,
}) => (
    <ErrorBoundary page="account-page-credits">
        <CreditsList
            credits={credits}
            totalValue={totalValue}
            hasMoreCredits={hasMoreCredits}
            fetchConsumerCredits={fetchConsumerCredits}
            clearServerError={clearServerError}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, creditsHistory, initialized }) => {
    return {
        currentUser: currentUser,
        credits: creditsHistory && creditsHistory.credits,
        totalValue: creditsHistory.totalValue,
        hasMoreCredits: creditsHistory && creditsHistory.hasMore,
        initialized: initialized,
        serverError: !!creditsHistory.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchConsumerCredits: page => dispatch(fetchConsumerCredits(page)),
        clearServerError: () => dispatch(clearError('FETCH_CONSUMER_CREDITS')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageCredits)
