import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'

export const mapStateToProps = (
    {
        localeList,
        locales,
        cart,
        currentUser,
        currentLocale,
        currentGlobalOrder,
        initRequest,
    },
    { location: { state: inheritedProps }, parallelGlobalOrderId }
) => {
    try {
        return {
            parallelGlobalOrderId:
                parallelGlobalOrderId ||
                (!!inheritedProps && !!inheritedProps.parallelGlobalOrderId
                    ? inheritedProps.parallelGlobalOrderId
                    : undefined),
            cart,
            currentGlobalOrder,
            currentLocale,
            currentUser,
            error: !!initRequest.error,
            localesReady: localeList && localeList.length > 0,
            localesLoading: locales && !!locales.loading,
            locales: localeList
                .filter(l => {
                    if (!!currentUser && currentUser.pro) {
                        return l === 'PRO'
                    }
                    if (locales[l].private) {
                        return !!currentUser && currentUser.default_locale === l
                    }
                    return l !== 'PRO'
                })
                .map(id => locales[id])
                .slice(0)
                .sort(sortByProps(['name'])),
        }
    } catch (err) {
        window.location.reload()
    }
}
