import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import sortByProps from '@kelbongoo/shared-methods/utils/sortByProps'
import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'
import {
    Form,
    Message,
    Icon,
    Header,
    Button,
    Popup,
    Dimmer,
    Loader,
    Checkbox,
} from 'semantic-ui-react'

import { Redirect } from 'react-router'
import Styles from './styles.module.css'

import { validateConsumerProfileUpdate } from './helpers/validateConsumerProfileUpdate'
import { diff } from './helpers/diff'

import {
    forgotPassword,
    sendUpdateConsumerEmailConfirmation,
    updateConsumerData,
    clearError,
} from '../../../../actions'

import usePlacesAutocomplete, {
    getGeocode,
    getZipCode,
} from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxPopover, ComboboxList } from '@reach/combobox'

import InfoModal from '../../../ui/InfoModal'
import ErrorBoundary from '../../../layout/ErrorBoundary'

import { AddressHeader } from './AddressHeader'
import { AccountPageDetailsUpdateEmailModal } from './AccountPageDetailsUpdateEmailModal'
import { AccountPageDetailsInvalidAddressWarning } from './AccountPageDetailsInvalidAddressWarning'
import {
    isUrlDeliveryAddressInvalid,
    formatErrorText,
    userKeys,
} from './helpers'

const DetailsPage = ({
    serverError,
    updateParams,
    onResetPassword,
    clearServerError,
    currentUser,
    locales,
    sendUpdateEmailConfirmation,
}) => {
    let checkoutInvalidDeliveryAddressRedirect =
        isUrlDeliveryAddressInvalid(window.location)

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    const [validated, setValidated] = useState(true)
    const [loading, setLoading] = useState(false)
    const [init, setInit] = useState(true)
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
    const [validationRequired, setValidationRequired] = useState(false)
    const [redirectCheckout, setRedirectCheckout] = useState(false)
    const [showFormError, setShowFormError] = useState(null)
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(null)
    const [showFormSuccess, setShowFormSuccess] = useState(null)
    const [updateAddress, setUpdateAddress] = useState(false)

    //Current user data fields
    const [telephone, setTelephone] = useState(currentUser && currentUser.telephone)
    const [email, setEmail] = useState(currentUser && currentUser.email)
    const [firstname, setFirstname] = useState(currentUser && currentUser.firstname)
    const [lastname, setLastname] = useState(currentUser && currentUser.lastname)
    const [default_locale, setDefault_locale] = useState(currentUser && currentUser.default_locale)
    const [send_launch_email, setSend_launch_email] = useState(currentUser && currentUser.send_launch_email)
    const [address_street, setAddress_street] = useState(currentUser && currentUser.address_street)
    const [address_city, setAddress_city] = useState(currentUser && currentUser.address_city)
    const [address_zip, setAddress_zip] = useState(currentUser && currentUser.address_zip)
    const [address_code, setAddress_code] = useState(currentUser && currentUser.address_code)
    const [address_instructions, setAddress_instructions] = useState(currentUser && currentUser.address_instructions)
    const [has_valid_delivery_address, setHas_valid_delivery_address] = useState(currentUser && currentUser.has_valid_delivery_address)

    let handleSelectAddress = async function (address) {
        clearSuggestions()
        setValue(address)

        const results = await getGeocode({ address })
        setAddress_city(results[0].address_components[2].long_name)
        setAddress_zip(getZipCode(results[0], false))
        setAddress_street(`${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`)
    }

    let getDerivedStateFromProps = function (props, state) {
        if (currentUser.loading === false) {
            if (!!init) {
                return {
                    ...pickProperties.run(state, ['email', ...userKeys]),
                    ...pickProperties.run(currentUser, [
                        'email',
                        ...userKeys,
                    ]),
                    init: false,
                    loading: false,
                }
            } else {
                return state
            }
        }
        return null
    }

    let handleResetPasswordSuccessDismiss = function () {
        setShowResetPasswordSuccess(false)
    }

    let handleFormSuccessDismiss = function () {
        setShowFormSuccess(false)
    }

    let handleFormErrorDismiss = function () {
        setShowFormError(false)
    }

    let handleErrorDismiss = function () {
        clearServerError()
    }

    const showInvalidAddressWarning =
        checkoutInvalidDeliveryAddressRedirect &&
        !has_valid_delivery_address


    const localeOptions = locales && locales
        .map(l => ({
            key: l.code,
            value: l.code,
            text: l.name,
        }))
        .slice()
        .sort(sortByProps(['text']))

    const handleSubmit = function () {
        setValue('')
        setUpdateAddress(false)

        let updatedUser = { firstname, lastname, telephone, default_locale, send_launch_email, has_valid_delivery_address, address_city, address_code, address_instructions, address_street, address_zip }
        const changed = diff(currentUser, updatedUser)

        if (!validateConsumerProfileUpdate(currentUser, updatedUser)) {
            return
        }

        setLoading(true)

        // validate the form
        return updateParams(changed)
            .then(ok => {
                if (ok) {
                    setValidated(false)
                    setHas_valid_delivery_address(currentUser.has_valid_delivery_address)
                    // setRedirectCheckout(checkoutInvalidDeliveryAddressRedirect)
                } else {
                    setLoading(false)
                    setShowFormError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    if (validationRequired) {
        return (
            <div>
                <Form>
                    <Header
                        as="h3"
                        textAlign="center"
                        icon
                    >
                        <Icon name="user plus" />
                        Validation email requise!
                    </Header>
                </Form>
            </div>
        )
    }

    if (redirectCheckout) {
        return (
            <Redirect
                to="/checkout"
                push
            />
        )
    }

    return (
        <Dimmer.Dimmable dimmed={loading}>
            <Dimmer
                active={loading}
                inverted
            >
                <Loader size="medium">Chargement</Loader>
            </Dimmer>

            <InfoModal
                show={!!serverError}
                icon="info circle"
                header=""
                body={serverError}
                onClose={handleErrorDismiss}
                isError={true}
            />
            <InfoModal
                show={!!showFormError}
                onClose={handleFormErrorDismiss}
                isError={true}
                header={"Merci de vérifier cette adresse"}
                body={
                    "Cette adresse n'est pas reconnue comme une adresse de livraison valide"
                }
            />
            <InfoModal
                show={!!showFormSuccess}
                onClose={handleFormSuccessDismiss}
                isSuccess={true}
            />
            <InfoModal
                show={!!showResetPasswordSuccess}
                body="Un mail vient de vous être envoyé avec un lien pour changer votre mot de passe."
                onClose={handleResetPasswordSuccessDismiss}
                isInfo={true}
            />

            <div className={Styles.Container}>
                <Form>
                    {currentUser && currentUser.pro && (
                        <Message
                            size="mini"
                            icon="info circle"
                            header="Vous avez un compte professionnel"
                            style={{ marginBottom: '2em' }}
                        />
                    )}

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>Mon profil :</div>
                        <Form.Field
                            required
                            style={{ margin: '1em 0' }}
                        >
                            <label htmlFor="lastname">Nom</label>
                            <input
                                name="lastname"
                                id="lastname"
                                placeholder="Nom"
                                value={lastname || currentUser.lastname || ''}
                                onChange={e => setLastname(e.target.value)}
                            />
                        </Form.Field>

                        <Form.Field
                            required
                            style={{ margin: '1em 0' }}
                        >
                            <label htmlFor="firstname">Pr&eacute;nom</label>
                            <input
                                name="firstname"
                                id="firstname"
                                placeholder="Prénom"
                                value={firstname || currentUser.firstname || ''}
                                onChange={e => setFirstname(e.target.value)}
                            />
                        </Form.Field>

                        <div className={Styles.Align}>
                            <Form.Field>
                                <label htmlFor="email">Adresse mail</label>
                                <AccountPageDetailsUpdateEmailModal
                                    email={email}
                                    sendUpdateEmailConfirmation={
                                        sendUpdateEmailConfirmation
                                    }
                                />
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="password">Mot de passe</label>
                                <Button
                                    type="button"
                                    loading={
                                        resetPasswordLoading
                                    }
                                    content="Changer mon mot de passe"
                                    onClick={onResetPassword}
                                />
                            </Form.Field>
                        </div>

                        <Form.Field>
                            <label htmlFor="telephone">
                                T&eacute;l&eacute;phone &nbsp;
                                <Popup
                                    trigger={
                                        <Icon name="question circle" />
                                    }
                                    content="Si vous choisissez de régler votre commande lors du retrait,
                    nous aurons besoin de votre numéro de téléphone"
                                />
                            </label>
                            <input
                                name="telephone"
                                id="telephone"
                                placeholder="Numéro de téléphone"
                                value={telephone || currentUser.telephone || ''}
                                onChange={e => setTelephone(e.target.value)}
                            />
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>
                            Mes préférences :
                        </div>
                        <Form.Field>
                            <label style={{ marginTop: '15px' }}>
                                Boutique par d&eacute;faut &nbsp;
                                <Popup
                                    trigger={
                                        <Icon name="question circle" />
                                    }
                                    content="La boutique où vous commandez habituellement.
                      Vous pouvez toujours choisir une autre boutique si vous voulez !"
                                />
                            </label>
                            <Form.Select
                                fluid
                                options={localeOptions}
                                placeholder="Choisir une boutique"
                                name="default_locale"
                                value={default_locale || currentUser.default_locale || ''}
                                onChange={e => setDefault_locale(e.target.value)}
                                data-testid="default_locale"
                            />
                        </Form.Field>

                        <Form.Field style={{ margin: '2em 0 1em' }}>
                            <Checkbox
                                toggle
                                label="Rappel à l'ouverture des commandes"
                                name="send_launch_email"
                                id="send_launch_email"
                                onChange={e => setSend_launch_email(e.target.value)}
                                checked={send_launch_email || currentUser.send_launch_email || false}
                                data-testid="send_launch_email"
                            />
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.Title}>Mon adresse :</div>

                        {/* Delivery address group */}
                        <AccountPageDetailsInvalidAddressWarning
                            showInvalidAddressWarning={
                                showInvalidAddressWarning
                            }
                        />

                        <AddressHeader
                            has_valid_delivery_address={
                                currentUser.has_valid_delivery_address
                            }
                        />

                        <div className={Styles.Align}>
                            <Form.Field className={Styles.Street}>
                                <label htmlFor="address_street">Rue</label>
                                <input
                                    name="address_street"
                                    id="address_street"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_street || currentUser.address_street || ''}
                                />
                            </Form.Field>
                            <Form.Field className={Styles.City}>
                                <label htmlFor="address_city">Ville</label>
                                <input
                                    name="address_city"
                                    id="address_city"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_city || currentUser.address_city || ''}
                                />
                            </Form.Field>
                            <Form.Field className={Styles.PostalCode}>
                                <label htmlFor="address_zip">
                                    Code postal
                                </label>
                                <input
                                    name="address_zip"
                                    id="address_zip"
                                    disabled
                                    // onChange={this.handleChange.bind(this)}
                                    value={address_zip || currentUser.address_zip || ''}
                                />
                            </Form.Field>
                        </div>
                        {!updateAddress ?
                            <Form.Field style={{ margin: '10px 0 15px 0 !important' }}>
                                <Button
                                    type="button"
                                    style={{ backgroundColor: '#63AE71', color: '#075244' }}
                                    content="Mettre a jour mon adresse"
                                    onClick={() => setUpdateAddress(true)}
                                />
                            </Form.Field>
                            :
                            <div
                                className={Styles.Street}
                                style={{ margin: '10px 0px 15px 0', width: '100%' }}>
                                <label htmlFor="address" style={{ fontWeight: 'bold', fontSize: '.92857143em' }}>Rechercher une adresse</label>
                                <Combobox onSelect={handleSelectAddress} style={{ margin: '.28571429rem 0 0 !important' }}>
                                    <ComboboxInput
                                        name="address"
                                        id="address"
                                        autocomplete="off"
                                        placeholder="Renseignez une adresse..."
                                        onChange={e => {
                                            setValue(e.target.value)
                                        }}
                                        value={value}
                                    />
                                    <ComboboxPopover style={{ zIndex: '1500' }}>
                                        <ComboboxList>
                                            {data.map(({ place_id, description }, index) => {
                                                return (
                                                    <ComboboxOption
                                                        key={place_id}
                                                        value={description}
                                                    />
                                                )
                                            })}
                                        </ComboboxList>
                                    </ComboboxPopover>
                                </Combobox>
                            </div>
                        }
                        <Form.Field style={{ marginBottom: '1em' }}>
                            <label htmlFor="address_code">
                                Code immeuble
                            </label>
                            <input
                                name="address_code"
                                id="address_code"
                                placeholder=''
                                onChange={e => setAddress_code(e.target.value)}
                                value={address_code || currentUser.address_code || ''}
                            />
                        </Form.Field>
                        <Form.Field style={{ marginBottom: '1em' }}>
                            <label htmlFor="address_instructions">
                                Instructions &agrave; donner au livreur
                            </label>
                            <textarea
                                name="address_instructions"
                                id="address_instructions"
                                rows={8}
                                placeholder="Instructions supplémentaires (200 caracteres max)"
                                onChange={e => setAddress_instructions(e.target.value)}
                                value={address_instructions || currentUser.address_instructions || ''}
                                className={Styles.Textarea}
                            />
                            <label htmlFor="address_instructions">
                                Livraison en pied d'immeuble. Exception
                                possible pour les personnes à mobilité
                                réduite, auquel cas merci de préciser
                                ci-dessus votre souhait d'être livré à la
                                porte.
                            </label>
                        </Form.Field>
                    </div>

                    <div className={Styles.Module}>
                        <div className={Styles.ActionPanel}>
                            <Button
                                secondary
                                disabled={
                                    checkoutInvalidDeliveryAddressRedirect
                                        ? true
                                        : !!validated
                                }
                                content={'Retour à la vente'}
                                icon="shopping basket"
                                href="/commande"
                            />

                            <Form.Field>
                                <Button
                                    primary
                                    disabled={!validated}
                                    content={
                                        checkoutInvalidDeliveryAddressRedirect
                                            ? 'Valider et procéder au paiement'
                                            : 'Valider'
                                    }
                                    icon="checkmark"
                                    onClick={() => handleSubmit()}
                                />
                            </Form.Field>
                        </div>
                    </div>
                </Form>
            </div>
        </Dimmer.Dimmable>
    )
}

const AccountPageDetails = ({
    currentUser,
    locales,
    serverError = '',
    sendUpdateEmailConfirmation,
    updateParams,
    onResetPassword,
    clearServerError,
    location,
}) => (
    <ErrorBoundary page="account-page-details">
        <DetailsPage
            currentUser={currentUser}
            locales={locales}
            serverError={serverError}
            sendUpdateEmailConfirmation={sendUpdateEmailConfirmation}
            updateParams={updateParams}
            onResetPassword={onResetPassword}
            clearServerError={clearServerError}
            location={location}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({ currentUser, localeList, locales }) => {
    return {
        currentUser,
        locales: localeList
            .filter(code =>
                locales[code] &&
                !locales[code].private &&
                locales[code].code !== 'PRO'
            )
            .map(code => locales[code]),
        serverError: !!currentUser.error && formatErrorText(currentUser.error),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendUpdateEmailConfirmation: email =>
            dispatch(sendUpdateConsumerEmailConfirmation(email)),
        updateParams: params => dispatch(updateConsumerData(params)),
        onResetPassword: params => dispatch(forgotPassword(params)),
        clearServerError: () => dispatch(clearError('CURRENT_USER')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDetails)
