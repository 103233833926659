import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Icon, Header, Popup, Select, Grid, Button } from 'semantic-ui-react'

import {
    createConsumer,
    fetchLocales,
} from '../../../actions'
import ErrorBoundary from '../../layout/ErrorBoundary'

import usePlacesAutocomplete, {
    getGeocode,
    getZipCode,
} from 'use-places-autocomplete'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxPopover, ComboboxList } from '@reach/combobox'

import pickProperties from '@kelbongoo/shared-methods/utils/pickProperties'

import { CreateAccountValidationNotification } from './CreateAccountValidationNotification'

import Styles from './styles.module.css'
import { CreateAccountFooter } from './CreateAccountFooter'

let zipCodes = ["75001", "75002", "75003", "75004", "75005", "75006", "75007", "75008", "75009", "75010", "75011", "75012", "75013", "75014", "75015", "75016", "75116", "75017", "75018", "75019", "75020", "92200", "93400", "93200", "93210", "92110", "92400", "93500", "93300", "92600", "93450", "93260", "92230", "93310", "92160", "92220", "92270", "92100", "92340", "92290", "92320", "92370", "92140", "92140", "92700", "92260", "92380", "92250", "92130", "92300", "92240", "92430", "92190", "92360", "92120", "92000", "92350", "92350", "92800", "92500", "92500", "92210", "92330", "92310", "92150", "92170", "92420", "92410", "92390", "94160", "93160", "93340", "93250", "93360", "93110", "93022", "93170", "94300", "94220"]

const signupFields = [
    'firstname',
    'lastname',
    'email',
    'default_locale',
    'signup_reason',
    'telephone',
    'address_street',
    'address_city',
    'address_zip',
    'address_code',
    'address_instructions',
]

export const CreateAccountBase = ({
    currentLocale,
    showAddressForm,
    currentUser,
    locales,
    fetchLocales,
    createConsumer
}) => {
    const localeOptions = locales
        .filter(l => l.code !== 'PRO' && !l.private)
        .map(l => ({
            key: l.code,
            value: l.code,
            text: l.name,
        }))

    const [error, setError] = useState(false)
    const [validationRequired, setValidationRequired] = useState(false)
    const [updateAddress, setUpdateAddress] = useState(true)

    const [telephone, setTelephone] = useState('')
    const [email, setEmail] = useState('')
    const [firstname, setFirstname] = useState(currentUser.livdom ? currentUser.livdom.firstname : '')
    const [default_locale, setDefault_locale] = useState(currentLocale && currentLocale.code)
    const [lastname, setLastname] = useState(currentUser.livdom ? currentUser.livdom.lastname : '')
    const [address_street, setAddress_street] = useState(currentUser.livdom ? currentUser.livdom.address_street : '')
    const [address_city, setAddress_city] = useState(currentUser.livdom ? currentUser.livdom.address_city : '')
    const [address_zip, setAddress_zip] = useState(currentUser.livdom ? currentUser.livdom.address_zip : '')
    const [address_code, setAddress_code] = useState('')
    const [address_instructions, setAddress_instructions] = useState(currentUser.livdom ? currentUser.livdom.address_instructions : '')
    const [signup_reason, setSignup_reason] = useState('')

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    async function handleSelectAddress(address) {
        clearSuggestions()
        setValue(address)
        setUpdateAddress(false)

        const results = await getGeocode({ address })
        setAddress_city(results[0].address_components[2].long_name)
        setAddress_zip(getZipCode(results[0], false))
        setAddress_street(`${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`)
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (currentUser.loading) {
            return
        }

        const payload = pickProperties.run({ firstname, lastname, email, default_locale, signup_reason, telephone, address_street, address_city, address_zip, address_code, address_instructions }, signupFields)

        setError(false)

        createConsumer(payload)
            .then(result => {
                if (result.message === 'Consumer created successfully') {
                    // gtm registration successful
                    window.dataLayer &&
                        window.dataLayer.push({
                            event: 'sign_up',
                            eventProps: {
                                boutique: default_locale,
                            },
                        })

                    setValidationRequired(true)
                } else {
                    // gtm registration not successful
                    window.dataLayer &&
                        window.dataLayer.push({
                            event: 'sign_up_error',
                            eventProps: {
                                error: `${JSON.stringify(result)}`
                            }
                        })

                    setError(`${JSON.stringify(result)}`)
                }
            })
            .catch(err => {
                // gtm registration not successful
                window.dataLayer &&
                    window.dataLayer.push({
                        event: 'sign_up_error',
                        eventProps: {
                            error: `${JSON.stringify(err.message)}`,
                        }
                    })

                setError(`${JSON.stringify(err.message)}`)
            })
    }

    const signupReasonOptions = [
        { key: '1', value: 'Recommandé par un ami', text: 'Recommandé par un ami' },
        { key: '2', value: 'Recherche internet', text: 'Recherche internet' },
        { key: '3', value: 'Publicité en ligne', text: 'Publicité en ligne' },
        { key: '4', value: 'Presse & médias', text: 'Presse & médias' },
    ]

    useEffect(() => {
        if (!locales || locales.length === 0) {
            fetchLocales()
        }

        if (address_street) {
            setUpdateAddress(false)
        }

        window.scrollTo(0, 0)
    }, [locales, address_street])

    if (currentUser.loggedin) {
        return <Redirect to="/commande" />
    }

    if (validationRequired) {
        return <CreateAccountValidationNotification livdom={currentUser.livdom ? true : false} />
    }

    return (
        <div
            className={`${Styles.Container} ${showAddressForm ? Styles.includeAddress : ''
                }`}
        >
            <Form
                onSubmit={e => handleSubmit(e)}
                data-testid="form"
            >
                <Grid stackable>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header
                                as="h3"
                                textAlign="center"
                                icon
                            >
                                <Icon name="user plus" />
                                Créer un compte
                                <Header.Subheader>
                                    Créez un compte pour profiter des
                                    services Kelbongoo.
                                </Header.Subheader>
                                <Header.Subheader
                                    className={Styles.Highlight}
                                >
                                    Nous nous engageons à ne jamais donner
                                    ou vendre vos données à des tiers.
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={showAddressForm ? 2 : 1}>
                        <Grid.Column>
                            <Form.Input
                                label="Prénom"
                                placeholder="Jean"
                                id="firstname"
                                name="firstname"
                                required
                                value={firstname || ''}
                                onChange={e => setFirstname(e.target.value)}
                            />

                            <Form.Input
                                label="Nom"
                                placeholder="Dupont"
                                id="lastname"
                                name="lastname"
                                required
                                value={lastname || ''}
                                onChange={e => setLastname(e.target.value)}
                            />

                            <Form.Input
                                label="Email"
                                placeholder="jean.dupont@email.com"
                                id="email"
                                name="email"
                                required
                                value={email || ''}
                                onChange={e => setEmail(e.target.value)}
                            />

                            <Form.Field>
                                <label>
                                    Lieu de retrait
                                    pr&eacute;f&eacute;r&eacute;
                                    <Popup
                                        trigger={
                                            <Icon name="question circle" />
                                        }
                                        content="La boutique où vous commandez habituellement.
                        Vous pouvez toujours choisir une autre boutique si vous voulez !"
                                    />
                                </label>
                                <Form.Select
                                    fluid
                                    required
                                    control={Select}
                                    selection
                                    options={localeOptions}
                                    value={default_locale}
                                    name="default_locale"
                                    onChange={(e, { value }) => {
                                        setDefault_locale(value)
                                    }}
                                    data-testid="default_locale"
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>
                                    Comment avez-vous connu Kelbongoo?
                                </label>
                                <Form.Select
                                    fluid
                                    required
                                    control={Select}
                                    options={signupReasonOptions}
                                    name="signup_reason"
                                    placeholder="Choisir une raison"
                                    onChange={e => setSignup_reason(e.target.value)}
                                    data-testid="signup_reason"
                                />
                            </Form.Field>
                        </Grid.Column>

                        {showAddressForm ? (
                            <Grid.Column>
                                <Form.Input
                                    label="Téléphone"
                                    placeholder="Numéro de téléphone"
                                    id="telephone"
                                    name="telephone"
                                    required
                                    value={telephone}
                                    onChange={e => setTelephone(e.target.value)}
                                />
                                {!updateAddress ?
                                    <Form.Field required>
                                        <Button
                                            type="button"
                                            style={{ backgroundColor: '#63AE71', color: '#075244' }}
                                            content="Mettre a jour mon adresse"
                                            onClick={() => setUpdateAddress(true)}
                                        />
                                    </Form.Field>
                                    :
                                    <>
                                        <label htmlFor="address" style={{ fontWeight: 'bold' }}>Choisissez une adresse de livraison</label>
                                        <Combobox onSelect={handleSelectAddress}>
                                            <ComboboxInput
                                                name="address"
                                                id="address"
                                                style={{ marginBottom: '1em' }}
                                                placeholder=""
                                                onChange={e => setValue(e.target.value)}
                                                value={value}
                                            />
                                            <ComboboxPopover style={{ zIndex: '1500' }}>
                                                <ComboboxList>
                                                    {data.map(({ place_id, description }, index) => {
                                                        return (
                                                            <ComboboxOption
                                                                key={place_id}
                                                                value={description}
                                                            />
                                                        )
                                                    })}
                                                </ComboboxList>
                                            </ComboboxPopover>
                                        </Combobox>
                                    </>
                                }

                                {address_street &&
                                    <>
                                        <Form.Field
                                            style={{ marginBottom: '1em' }}
                                            required
                                        >
                                            <label htmlFor="address_street">
                                                Rue
                                            </label>
                                            <input
                                                id="address_street"
                                                name="address_street"
                                                style={!address_street ? { opacity: '0.5 !important' } : {}}
                                                required
                                                disabled
                                                placeholder=""
                                                value={address_street}
                                            />
                                        </Form.Field>

                                        <Form.Group>
                                            <Form.Field
                                                width={10}
                                                required
                                            >
                                                <label htmlFor="address_city">
                                                    Ville
                                                </label>
                                                <input
                                                    id="address_city"
                                                    name="address_city"
                                                    style={!address_city ? { opacity: '0.5 !important' } : {}}
                                                    required
                                                    disabled
                                                    placeholder=""
                                                    value={address_city}
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                width={6}
                                                required
                                            >
                                                <label htmlFor="address_zip">
                                                    Code postal
                                                </label>
                                                <input
                                                    id="address_zip"
                                                    name="address_zip"
                                                    style={!address_zip ? { opacity: '0.5 !important' } : {}}
                                                    required
                                                    disabled
                                                    placeholder=""
                                                    value={address_zip}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        {address_zip ? (
                                            zipCodes.includes(address_zip) ?
                                                <p style={{ color: 'green', margin: '5px 0 0 0 !important' }}>Votre adresse est valide</p>
                                                :
                                                <p style={{ color: 'red', margin: '5px 0 0 0 !important' }}>Votre adresse n'est pas dans notre zone de livraison</p>)
                                            :
                                            <p style={{ color: 'transparent', margin: '5px 0 0 0 !important' }}>Renseignez une adresse</p>
                                        }
                                    </>
                                }

                                <Form.Input
                                    style={{ marginBottom: '1em' }}
                                    label="Code immeuble"
                                    placeholder="1234A"
                                    id="address_code"
                                    name="address_code"
                                    value={address_code}
                                    onChange={e => setAddress_code(e.target.value)}
                                />

                                <Form.Field style={{ marginBottom: '1em' }}>
                                    <label htmlFor="address_instructions">
                                        Instructions &agrave; donner au
                                        livreur ou &agrave; la livreuse
                                    </label>
                                    <textarea
                                        id="address_instructions"
                                        name="address_instructions"
                                        rows={3}
                                        placeholder="Instructions supplémentaires (200 caractères maximum)"
                                        onChange={e => setAddress_instructions(e.target.value)}
                                        value={address_instructions}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        ) : (
                            ''
                        )}
                    </Grid.Row>

                    <CreateAccountFooter
                        currentLocale={currentLocale}
                        currentUser={currentUser}
                        error={error}
                        showAddressForm={showAddressForm}
                    />
                </Grid>
            </Form>
        </div>
    )
}

const CreateAccount = ({
    currentLocale,
    showAddressForm,
    currentUser,
    locales,
    createConsumer,
    fetchLocales,
}) => (
    <ErrorBoundary page="create-account">
        <CreateAccountBase
            currentLocale={currentLocale}
            currentUser={currentUser}
            showAddressForm={showAddressForm}
            locales={locales}
            createConsumer={createConsumer}
            fetchLocales={fetchLocales}
        />
    </ErrorBoundary>
)

const mapStateToProps = ({
    currentUser,
    currentLocale,
    locales,
    consumerSignupReasons,
}) => ({
    currentUser,
    signupReasons: consumerSignupReasons.reasons,
    locales: Object.values(locales),
    currentLocale:
        currentLocale && currentLocale.code ? currentLocale : { code: 'BOR' }, // ie allow signup even if total technical chaos...
    showAddressForm: (!!currentLocale && currentLocale.code === 'DOM') || currentUser.livdom,
})

const mapDispatchToProps = dispatch => {
    return {
        createConsumer: params => dispatch(createConsumer(params)),
        fetchLocales: () => dispatch(fetchLocales()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
