import React from 'react'
import { List, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

const getCardIconName = b => {
    var name = {
        VISA: 'cc visa',
        VISA_ELECTRON: 'cc visa',
        VPAY: 'cc visa',
        MASTERCARD: 'cc mastercard',
    }[b]
    return name || 'credit card'
}

export const CheckoutPaymentTokenItem = ({ token, handleSelection, index, handleDeleteToken }) => {


    console.log('token', token.token)


    return (
        <List.Item
            key={index}
            data-testid="cb-item"
            style={{ display: 'flex', alignItems: 'center' }}

        >
            <List.Icon
                name={getCardIconName(token.brand)}
                size="large"
                verticalAlign="middle"
            />

            <List.Content>
                <List.Header
                    as="a"
                    onClick={() => handleSelection(token.token)}
                >
                    {token.number}
                </List.Header>

                <List.Description>
                    {`${token.expiryMonth} / ${token.expiryYear}`}
                </List.Description>
            </List.Content>

            <Icon name="close" style={{ color: 'red', fontSize: '1.3em' }} onClick={() => handleDeleteToken(token.token)} />
        </List.Item>
    )
}