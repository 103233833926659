export const userKeys = [
    'firstname',
    'lastname',
    'default_locale',
    'telephone',
    'send_closing_email',
    'send_launch_email',
    'address_street',
    'address_city',
    'address_zip',
    'address_code',
    'address_instructions',
    'has_valid_delivery_address',
]
