import React, { Component } from 'react'

import Styles from './styles.module.css'

export class CheckoutPaymentCBIframe extends Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef()

        this.state = {
            isSent: false,
        }
    }

    componentDidMount() {
        if (!this.formRef) {
            return
        }

        this.formRef.current.submit()
        this.setState({
            isSent: true,
        })
    }

    render() {
        return (
            <div
                id="iframe_container"
                className={Styles.IframeContainer}
                data-testid="iframe-container"
            >
                <iframe
                    name="kelbongoo-payment"
                    id="kelbongoo-payment"
                    title="kelbongoo-payment"
                    maxHeight="90%"
                    width="90%"
                    height="450"
                    className={Styles.IFrame}
                >
                    {!this.state.isSent && (
                        <form
                            method="POST"
                            action={`https://${this.props.hostname}${this.props.path}`}
                            target="kelbongoo-payment"
                            ref={this.formRef}
                        >
                            {Object.keys(this.props.data).map(key => (
                                <input
                                    type="hidden"
                                    name={key}
                                    key={key}
                                    value={this.props.data[key]}
                                />
                            ))}
                        </form>
                    )}
                </iframe>
            </div>
        )
    }
}
