import React, { Component } from 'react'
import PropTypes, { array } from 'prop-types'
import { Grid, Icon, Divider, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import {
    updateCartItem,
    setOrderContext,
    openZoom,
    closeZoom,
    runProductSearch,
    setSideMenuVisibility,
} from '../../../../actions'

import ProductZoom from '../ProductZoom'
import { PersistentContextBar } from '../PersistentContextBar'
import { mapStateToProps } from './helpers/mapStateToProps'
import { ProductsHeader } from './ProductsHeader'
import { CategoryView } from './CategoryView'

import Styles from './styles.module.css'
import CategoryScroller from '../CategoryScroller'
import { HeaderSearchProductModal } from '../../../layout/Header/HeaderSearchProductModal'

const addCart = () => () => { }

export class ProductList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            familyScrollIndex: false,
            modalOpen: undefined,
            error: false,
            open: false,
            itemIndex: 0,
            scrollerItems: undefined
        }
        this.onPassNewSection = this.onPassNewSection.bind(this)
        this.triggerModal = this.triggerModal.bind(this)
        this.onChangeValue = this.onChangeValue.bind(this)
        this.onOpenZoom = this.onOpenZoom.bind(this)
        this.handleSearchSelect = this.handleSearchSelect.bind(this)
        this.onCloseZoom = this.onCloseZoom.bind(this)
        this.updateitemIndex = this.updateitemIndex.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let scrollerItems = this.props.categories.slice()
        let produitsDuMomentIndex = scrollerItems.findIndex(c => c.name.toLowerCase() === 'produits du moment')
        let produitsDuMoment = scrollerItems[produitsDuMomentIndex]

        if (produitsDuMomentIndex > -1) {
            scrollerItems.splice(produitsDuMomentIndex, 1)
            produitsDuMoment.families.slice().reverse().forEach(f => {
                f['family'] = true
                scrollerItems.unshift(f)
            })
        }

        // Filtering out empty categories
        scrollerItems.forEach((i, index) => {
            let treeCategory = this.props.productTree.find(t => t.name === i.name)
            if (!treeCategory) {
                scrollerItems.splice(index, 1)
            }
        })

        this.setState({
            ...this.state,
            scrollerItems: scrollerItems
        })
    }

    onChangeValue({ productId, quantity, lastAction, isConsumerCart = false }) {
        this.props.updateCartItem({ productId, quantity, lastAction })

        if (this.props.zoomProduct._id === productId && !isConsumerCart) {
            const product = {
                ...this.props.zoomProduct,
                quantity,
            }
            this.props.openZoom({ product })
        }
    }

    triggerModal(type) {
        this.setState({
            modalOpen: this.state.modalOpen ? undefined : type,
            error: false,
        })
    }

    updateitemIndex(item) {
        this.setState({
            ...this.state,
            itemIndex: this.state.scrollerItems.findIndex(c => c._id === item)
        })
    }

    handleSearchSelect(data) {
        this.setState({
            modalOpen: undefined,
            error: false,
        })
        this.props.eventEmitter.emit('search-select', data)
    }

    onPassNewSection({ category, family, selectedCategory }, e, { calculations }) {
        if (this.props.blockVisibility) {
            return
        }

        if (window.innerWidth > 1024) {
            if (category && family && calculations && calculations.passing) {
                this.props.setOrderContext({ family, category, selectedCategory })
            } else {
                /* make sure we put back the generic title when we re-scroll to the top */
                this.props.setOrderContext({ family: null, category: null, selectedCategory })
            }
        }
    }

    onOpenZoom({ product }) {
        this.props.openZoom({ product })
    }

    onCloseZoom() {
        this.props.closeZoom()
    }

    render() {
        const {
            cart,
            categories,
            currentDate,
            currentGlobalOrder,
            currentLocale,
            currentUser,
            runSearch,
            families,
            isGlobalOrderFull: isFull,
            productsHeader,
            productTree,
            scrollCategory,
            scrollFamily,
            selectedCategory,
            setSideMenuVisibility,
            sideMenuVisible,
            zoomProduct,
        } = this.props

        return (
            <div
                id="key-top"
                data-testid="product-list"
            >
                {/* product detail modal */}
                <ProductZoom
                    open={!!zoomProduct ? true : false}
                    closeCallback={this.onCloseZoom}
                    cart={cart}
                    product={zoomProduct}
                    onChangeValue={this.onChangeValue}
                    isFull={isFull}
                />

                {/* Subheader beneath global header, shows current category / family */}
                <PersistentContextBar
                    category={categories[scrollCategory]}
                    currentDate={currentDate}
                    currentGlobalOrder={currentGlobalOrder}
                    family={families[scrollFamily]}
                    currentLocale={currentLocale}
                    setSideMenuVisibility={setSideMenuVisibility}
                    sideMenuVisible={sideMenuVisible}
                />

                {/* Dynamic seasonal header */}
                <ProductsHeader
                    productsHeader={productsHeader}
                    currentUser={currentUser}
                />

                <Divider id="key-second-top" />

                {(window.innerWidth < 500 || (window.innerWidth < 767 && window.innerHeight > window.innerWidth)) && this.state.scrollerItems &&
                    <div className={Styles.Wrapper}>
                        <CategoryScroller
                            handleMenuClick={this.props.handleMenuClick}
                            category={categories[scrollCategory]}
                            updateitemIndex={this.updateitemIndex}
                            scrollerItems={this.state.scrollerItems}
                            productTree={productTree}
                        />
                        {currentGlobalOrder && (
                            <div className={Styles.buttonWrapper}>
                                <div
                                    onClick={() => this.triggerModal('search')}
                                    title="search-modal-trigger"
                                    className={Styles.searchButton}
                                >
                                    <Icon
                                        name="search"
                                        size="normal"
                                        style={{ color: 'white', margin: '0 10px', fontSize: '1.5em !important' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }

                <div className={Styles.productsContainer}>
                    <Grid className={Styles.contentRight}>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {productTree.map(category => (
                                    <CategoryView
                                        category={category}
                                        selectedCategory={selectedCategory}
                                        onPassNewSection={this.onPassNewSection}
                                        onChangeValue={this.onChangeValue}
                                        onOpenZoom={this.onOpenZoom}
                                        isFull={isFull}
                                        addCart={this.props.addCart}
                                    />
                                ))}
                            </Grid.Column>
                        </Grid.Row>

                        {/* link to cart detail page */}
                        {/* <PersistentCheckoutBar hasCartItems={hasCartItems} /> */}
                    </Grid>

                    {window.innerWidth < 500 && this.state.scrollerItems &&
                        <Button
                            icon
                            className={Styles.bottomButton}
                            labelPosition='right'
                            onClick={() => {
                                let nextIndex = (this.state.itemIndex + 1 + this.state.scrollerItems.length) % this.state.scrollerItems.length
                                this.props.handleMenuClick({
                                    category: this.state.scrollerItems[nextIndex].family ? 'promotions' : this.state.scrollerItems[nextIndex]._id,
                                    family: this.state.scrollerItems[nextIndex].family ? this.state.scrollerItems[nextIndex]._id : null,
                                    selectedCategory: this.state.scrollerItems[nextIndex].family ? 'promotions' : this.state.scrollerItems[nextIndex]._id,
                                    selectedFamily: this.state.scrollerItems[nextIndex].family ? this.state.scrollerItems[nextIndex]._id : null
                                })
                                this.setState({
                                    ...this.state,
                                    itemIndex: this.state.itemIndex + 1
                                })
                            }}
                        >
                            {this.state.scrollerItems[(this.state.itemIndex + 1 + this.state.scrollerItems.length) % this.state.scrollerItems.length].name.toUpperCase()}
                            <Icon name='right arrow' />
                        </Button>
                    }
                </div>

                <HeaderSearchProductModal
                    searchProductModalOpen={this.state.modalOpen === 'search'}
                    closeSearchProductModal={() => this.triggerModal('search')}
                    runSearch={runSearch}
                    onSelect={this.handleSearchSelect}
                />

                <div
                    style={{ height: '30px' }}
                    id="#tracker"
                />
            </div>
        )
    }
}

ProductList.propTypes = {
    cart: PropTypes.object,
    currentLocale: PropTypes.object,
    currentUser: PropTypes.object,
    hasCartItems: PropTypes.bool,
    isGlobalOrderFull: PropTypes.bool,
    productsHeader: PropTypes.object,
    productTree: PropTypes.array,
    sideMenuVisible: PropTypes.bool,
    // startScroll: PropTypes.string,
    scrollFamily: PropTypes.string,
    scrollCategory: PropTypes.string,
    zoomProduct: PropTypes.string,
    setSideMenuVisibility: PropTypes.func.isRequired,
    updateCartItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
    return {
        updateCartItem: params => dispatch(updateCartItem(params)),
        openZoom: params => dispatch(openZoom(params)),
        runSearch: search => dispatch(runProductSearch(search)),
        setOrderContext: params => dispatch(setOrderContext(params)),
        closeZoom: () => dispatch(closeZoom()),
        addCart: params => dispatch(addCart(params)),
        setSideMenuVisibility: ({ visible }) =>
            dispatch(setSideMenuVisibility({ visible })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
